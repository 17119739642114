import { lazy, Suspense, useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from './Firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, getParams } from './Action/ActionReducer';
import { Grid } from '@mui/material';
import useStyle from './Component/MakeStyle/HomeStyle.js';
import { Script } from './Firebase/Gtag';
import { ScriptPixel } from './Firebase/Pixel';
import './bo.css';
const Footer = lazy(() => import('./Component/Footer/Footer'));
const Home = lazy(() => import('./Component/Home/Home'));

function App() {
  const categoryDB = collection(db, 'category');
  const classes = useStyle();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getParams());

    onSnapshot(categoryDB, (snapShot) => {
      dispatch(
        addCategory(snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
    });
  }, []);
  return (
    <>
      <Suspense>
        <Grid
          sx={{
            display: 'flex',

            left: 0,

            width: '100%',

            backgroundColor: 'black',
          }}
        >
          <Home />
        </Grid>
      </Suspense>
    </>
  );
}

export default App;
